import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Plyr from "plyr";
import { api } from "../services/api";
import { useHistory, useLocation } from "react-router";
import { Wrapper } from "./VideoPlayer.styles";
import {
  AiOutlinePlayCircle,
  AiOutlineArrowLeft,
  AiOutlineMore,
  AiOutlineClose,
} from "react-icons/ai";
import { FiMusic } from "react-icons/fi";
import { RiMessage2Line } from "react-icons/ri";
import { VscArrowLeft } from "react-icons/vsc";
import { documentTitle, mediaSession } from "../utils/documentTitle";
import { SongContext } from "../context/SongProvider";
import { useTranslation } from "react-i18next";
import { getVideoDetails, getVideoURL, getVideoComments } from "../api/video";
import { getArtistIdentity } from "../api/artist";
import VideoComments from "./VideoComments";
import { Comment } from "./Comments";
import { MessageContext } from "../context/MessageProvider";
import { useParams } from "react-router-dom";
import { PlayerContext } from "../context/PlayerProvider";

interface LocationType {
  state: {
    mvId: number;
    artistId: number;
  };
  pathname: string;
}

interface VideoInfo {
  name: string;
  artistName: string;
  artistCover: string;
  artistId: number;
  cover: string;
}

const fetchVideoSrc = async (id: number) => {
  try {
    const response = await getVideoURL(id); // api.get(`/mv/url?id=${id}`);
    return response.data.data.url;
  } catch (error) {
    console.error(error);
  }
};

const fetchVideoInfo = async (id: number) => {
  try {
    const response = await getVideoDetails(id); // api.get(`/mv/detail?mvid=${id}`);
    return response.data.data;
  } catch (error) {
    console.error(error);
  }
};

const fetchComments = async (id: number) => {
  try {
    const response = await getVideoComments({ id, limit: 100 });
    return response.data.comments; //hotComments
  } catch (error) {
    console.error(error);
  }
};

function VideoPlayer() {
  const { t } = useTranslation();
  const [videoSrc, setVideoSrc] = useState("");
  const [videoInfo, setVideoInfo] = useState<VideoInfo>();
  const [videoComments, setVideoComments] = useState<Comment[]>([]);
  const [randomIndex, setRandomIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isPreviousMusicPlaying, setIsPreviousMusicPlaying] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBarrageShowing, setIsBarrageShowing] = useState(true);
  const [isCommentShowing, setIsCommentShowing] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  let player = useRef<any>(null);

  // const location: LocationType = useLocation();
  const params = useParams<{ artistId: string; mvId: string }>();
  const artistId = +params.artistId;
  const mvId = +params.mvId;
  const history = useHistory();

  const playerContext = useContext(PlayerContext);
  const messageContext = useContext(MessageContext);
  if (!playerContext || !messageContext) throw new Error();
  const { isMusicPlaying, setIsMusicPlaying } = playerContext;
  const { dispatch } = messageContext;

  // For video src and info fetching
  useEffect(() => {
    if (isMusicPlaying) {
      setIsMusicPlaying(false);
      setIsPreviousMusicPlaying(true);
    }
    if (mvId && artistId) {
      (async () => {
        const url = await fetchVideoSrc(mvId);
        setVideoSrc(url);
        const info = await fetchVideoInfo(mvId);
        // FIXME: If I move this "setVideoSrc" right below "const url =...", there will be a flicky issue with video element, not sure why
        // seems because the rerender caused by videoInfo?
        // after research, it because "new Plyr" cause another rerender, and if videoInfo is empty, the rerender will cause flicky, the deep reason may because the conflict between the render of "new Plyr" and videoInfo
        // Fixed! by using useLayoutEffect for "new Plyr"
        const artist = await getArtistIdentity(artistId);
        const artistCover = artist.data.data.artist.cover;
        setVideoInfo({ ...info, artistCover });
      })();
      // window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  // For plyr video player instantiation
  useLayoutEffect(() => {
    const videoOptions = {
      //debug: true,
      controls: [
        "play-large",
        // "play",
        "progress",
        "current-time",
        // "mute",
        // "volume",
        // "captions",
        // "settings",
        //"fullscreen",
      ],
      settings: ["captions", "quality", "speed", "loop"],
      autoplay: true,
      toggleInvert: true,
      clickToPlay: true,
      keyboard: { focused: true, global: true },
    };

    if (videoSrc) {
      player.current = new Plyr("#player", { ...videoOptions });

      player.current.on("pause", () => {
        setIsVideoPlaying(false);
      });

      player.current.on("play", () => {
        setIsVideoPlaying(true);
      });

      player.current.on("end", () => {
        setIsVideoPlaying(false);
      });
    }
  }, [videoSrc]);

  // For document title and media sessions
  useEffect(() => {
    if (videoInfo) {
      documentTitle(videoInfo.name, videoInfo.artistName);
      mediaSession(
        videoInfo.name,
        videoInfo.artistName,
        "",
        setIsVideoPlaying,
        () => {},
        () => {},
        () => {},
        1,
        videoInfo.cover + "?param=300y300"
      );

      if (player.current) {
        isVideoPlaying ? player.current.play() : player.current.pause();
      }
    }
  }, [videoInfo, isVideoPlaying]);

  // For video comments fetching
  useEffect(() => {
    if (mvId) {
      (async () => {
        const comments = await fetchComments(mvId);
        setVideoComments(comments);
      })();
    }
  }, []);

  //For comments changing as barrage
  useEffect(() => {
    let timer: any;
    // console.log("videoComments.length", videoComments.length);

    if (videoComments?.length) {
      timer = setInterval(() => {
        const randomValue = Math.abs(
          Math.floor(Math.random() * videoComments.length) - 2
        );
        // console.log(randomValue);
        setRandomIndex(randomValue);
      }, 6000);
    }

    // Pause the comment barrage when vide is paused
    if (!isVideoPlaying) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [videoComments, isVideoPlaying]);

  useEffect(() => {
    // To disable the click event bound with pause icon since it conflicts with click event assigned for the div wrapper of video
    if (player.current) {
      const pauseIcon = document.querySelector(
        ".plyr__control.plyr__control--overlaid"
      );

      // FIXME: Need to dig further about how to remove the event listener bound to an element
      //  if (pauseIcon instanceof HTMLElement) {
      //    pauseIcon.onclick = null;
      //    pauseIcon.setAttribute("onclick", ""); //.removeAttribute("onclick");
      //  }
    }
  }, [player.current]);

  //console.log(videoSrc, videoInfo);
  // console.log(player.current);

  const handleBackward = () => {
    isPreviousMusicPlaying && setIsMusicPlaying(true);
    //FIXME: A workaround for share mv, in that case, going to home page when clicking backward icon
    // 2022-02-25 18:05:47 Fixed by using document.referer
    // 2022-04-15 11:57:33 Enhanced by using session storage handle prev and current path
    // if (document.referrer) {
    //   history.goBack();
    // } else {
    //   history.push("/");
    // }

    const storage = globalThis?.sessionStorage;
    if (storage.prevPath) {
      history.goBack();
    } else {
      history.push("/");
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    const { action, artistid } = e.currentTarget.dataset;
    switch (action) {
      case "video-player":
        // console.log(player.current.playing, player.current);
        // FIXME: Not sure why player works without the settings below on desktop version (non touch screen?)
        // so add player.current.touch to check if it's touch screen or not. Needs to take a deep look
        // Might because of the default clickToPaly within setting options is true?
        player.current &&
          player.current.touch &&
          (player.current.playing
            ? player.current.pause()
            : player.current.play());
        break;
      case "settings":
        setIsMenuOpen((prev) => !prev);
        break;
      case "toggle-barrage":
        setIsBarrageShowing((prev) => !prev);
        setIsMenuOpen(false);
        break;
      case "full-screen":
        if (!document.fullscreenElement && videoRef.current) {
          try {
            videoRef.current.requestFullscreen();
            screen.orientation.lock("landscape-primary");
          } catch (error) {
            console.error(error);
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          }
        }
        // if (player.current) {
        //   player.current.fullscreen.enter();
        // }
        setIsMenuOpen(false);
        break;
      case "download-mv":
        // setIsBarrageShowing((prev) => !prev);
        dispatch({ type: "under-development" });
        setTimeout(() => {
          dispatch({ type: "none" });
        }, 3000);
        setIsMenuOpen(false);
        break;
      case "share":
        if (navigator.canShare() && videoInfo && artistId && mvId) {
          const { name, artistName } = videoInfo;
          navigator
            .share({
              title: name,
              text: `${name}-${artistName} ${window.location.hostname}/video/${artistId}/${mvId}`,
            })
            .then()
            .catch((error) => console.error(error));
        } else {
          dispatch({ type: "share-error" });
          return setTimeout(() => {
            dispatch({ type: "none" });
          }, 3000);
        }

        break;
      case "artist-info":
        //console.log("triggered", artistId);

        if (artistid) {
          history.push({ pathname: "/artist", state: { artistId: artistid } });
        }
        break;
      case "open-comments":
        setIsCommentShowing(true);
        //  history.push({
        //    pathname: location.pathname,
        //    search: "?video-comment",
        //    state: { background: location },
        //  });
        break;
      case "close-comments":
        setIsCommentShowing(false);
        // history.goBack();
        break;
      default:
        console.error("Error, no such case");
    }
  };

  return (
    <Wrapper isPlaying={isVideoPlaying} isCommentShowing={isCommentShowing}>
      <div className="header">
        <div className="back-icon" onClick={handleBackward}>
          <VscArrowLeft />
        </div>
        <div className="settings" data-action="settings" onClick={handleClick}>
          <AiOutlineMore />
        </div>
      </div>
      {isMenuOpen && (
        <div className="setting-menu">
          <ul>
            <li data-action="toggle-barrage" onClick={handleClick}>
              {/* <label htmlFor="barrage">Show Barrage</label><input type="checkbox" id="barrage" /> */}
              {isBarrageShowing
                ? t("video-player.close-barrage")
                : t("video-player.open-barrage")}
            </li>
            <hr />
            <li data-action="full-screen" onClick={handleClick}>
              {t("video-player.full-screen")}
            </li>
            <hr />
            {/* <li data-action="download-mv" onClick={handleClick}>
              {t("video-player.download-mv")}
            </li>
            <hr /> */}
            <li data-action="share" onClick={handleClick}>
              {t("action-panel.share")}
            </li>
          </ul>
        </div>
      )}
      {isBarrageShowing && (
        <div className="comment-area">
          <div className="comment1">
            <span>{videoComments?.[randomIndex]?.content}</span>
          </div>
          <div className="comment2">
            {videoComments?.[randomIndex + 1]?.content}
          </div>
          <div className="comment3">
            <span>{videoComments?.[randomIndex + 2]?.content}</span>
          </div>
        </div>
      )}
      <div
        className="video-player"
        data-action="video-player"
        onClick={handleClick}
      >
        {videoSrc ? (
          <video
            id="player"
            ref={videoRef}
            controls
            data-poster="/path/to/poster.jpg"
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        ) : (
          <div className="loading-indicator">{t("common.loading")}</div>
        )}
      </div>
      {/* <div
        className="pause-icon"
        data-action="video-player"
        onClick={handleClick}
      >
        <AiOutlinePlayCircle />
      </div> */}
      <div className="mv-info">
        {videoInfo ? (
          <div>
            <div
              className="song-artist"
              data-artistid={videoInfo.artistId}
              data-action="artist-info"
              onClick={handleClick}
            >
              <span className="artist-avatar">
                <img src={videoInfo.artistCover + "?param=60y60"} />
              </span>
              <span className="artist-name">{videoInfo.artistName}</span>
            </div>
            <div className="song-name">
              <span className="song-icon">
                <FiMusic />
              </span>
              <span>{videoInfo.name}</span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="mv-action-icons">
        <div
          className="comment-icon"
          data-action="open-comments"
          onClick={handleClick}
        >
          <RiMessage2Line />
        </div>
      </div>
      {isCommentShowing ? (
        <>
          <VideoComments comments={videoComments} handleClick={handleClick} />
        </>
      ) : null}
    </Wrapper>
  );
}

export default VideoPlayer;
